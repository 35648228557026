
import { defineComponent, PropType } from "vue";
import { ILocationCountry } from "@/store/locations";
import {
  IBaseLocation,
  ISubregion
} from "@/api/services/packages/esim/location-packages/types";
import { selectedLocationListType } from "@/api/services/locations/locationTypes";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import CountrySwitch from "@/components/ui/Country/CountrySwitch.vue";
import {
  switchedLocation,
  switchLocationDto
} from "@/components/Locations/interfaces";
import { TPackageLocationSingle } from "@/hooks/esim/location-packages/types/locationPackage.types";

export default defineComponent({
  name: "SelectedNestedLocations",
  components: { CountrySwitch, AppButton },

  props: {
    disabled: { type: Boolean, default: false },

    locationType: {
      type: String as PropType<TPackageLocationSingle>,
      required: true
    },

    selectedCounties: {
      type: Array as PropType<IBaseLocation[]>,
      default: () => []
    },

    selectedSubregions: {
      type: Array as PropType<IBaseLocation[]>,
      default: () => []
    }
  },
  emits: {
    turnOff: null,
    showCountriesSelect: null,
    showSubregionsSelect: null
  },

  data() {
    return {
      localSelectedCounties: this.selectedCounties,
      localSelectedSubregions: this.selectedSubregions
    };
  },

  computed: {
    showSubregionsSelection() {
      return this.locationType === "region";
    }
  },

  watch: {
    selectedCounties: {
      deep: true,
      immediate: true,
      handler(countries: ILocationCountry[]) {
        this.localSelectedCounties = countries;
      }
    },

    selectedSubregions: {
      deep: true,
      immediate: true,
      handler(subregions: ISubregion[]) {
        this.localSelectedSubregions = subregions;
      }
    }
  },

  methods: {
    switchLocalSubregion(subregion: switchLocationDto) {
      const data: switchedLocation = {
        id: subregion.id,
        type: "subregions",
        action: "disable"
      };

      this.localSelectedSubregions = this.localSelectedSubregions.filter(
        ({ id }) => {
          return id !== subregion.id;
        }
      );

      this.$emit("turnOff", data);
    },

    switchLocalCountry(country: switchLocationDto) {
      const data: switchedLocation = {
        id: country.id,
        type: "countries",
        action: "disable"
      };

      this.localSelectedCounties = this.localSelectedCounties.filter(
        ({ id }) => {
          return id !== country.id;
        }
      );

      this.$emit("turnOff", data);
    },

    showLocationsSelection(type: selectedLocationListType) {
      if (type === "country") {
        this.$emit("showCountriesSelect");
        return;
      }

      this.$emit("showSubregionsSelect");
    }
  }
});
